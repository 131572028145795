html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

.Overlay {
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 5;
  opacity: 0;
  visibility: hidden; 
  transition: opacity 0.5s ease, visibility 0s linear 0.5s; 
}

.Overlay.Show {
  opacity: 1; 
  visibility: visible; 
  transition: opacity 0.5s ease, visibility 0s;
}

.Close {
  cursor: pointer;
  position: static;
  float: right;
  margin-top: -2rem;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
  border: 0;
  color:#000000;
}


.know-more-section .container {
  overflow: auto;
  max-height: 100%;
  padding-bottom: 10px;
  padding-top: 30px;
  margin-top: 20px;
}


.know-more-section .Modal {
  position: fixed;
  width: 100%;
  bottom: -175vh;
  left: 0;
  background-color: #fff;  
  padding: 0 10px 10px;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);  
  transition: bottom 0.3s ease-in-out, top 0.3s ease-in-out;  
  opacity: 0;   
  z-index: 10;
}

.know-more-section .Modal.Show {
  bottom: 0;
  height: 80vh;
  max-height: 80vh;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  opacity: 1;
}

.know-more-section .title{
  font-family: "Smitch Sans";
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #1C1C1C;  
}

.know-more-section .description{
  font-family: "Smitch Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: 0em;
  text-align: left;
  color: #1C1C1C;  
  margin-top:20px;
}

.know-more-section .sub-description {
  font-family: "Smitch Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #494956;
  margin-top:20px;
}

.know-more-section .sub-description:last-child {
  margin-bottom: 2rem;
}

.know-more-section .multicolor-bar .range-text {
  font-family: 'Smitch Sans';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color:#000000;
  margin-top:30px;
  margin-bottom: 0;
}

.know-more-section .defintion{
  margin-top: 25px;
}

.know-more-section .defintion h4 {
  font-family: "Smitch Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #1C1C1C;
}

.know-more-section .defintion h4 .svg-inline--fa{
  width: 10px;
  height: 10px;
  margin-right:5px;
  margin-bottom: 1px;
}

.know-more-section .defintion p {
  font-family: "Smitch Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #606071
}

