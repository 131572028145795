* {
    box-sizing: border-box;
    font-family: 'Smitch Sans' !important;
}

.hide{
    display: none !important;
}
.hidden{
    visibility: hidden !important;
}

.back-question,
.next-question {
    width: 150px;
    height: 50px;
    padding: 13px 0px 13px 0px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}

.next-question {
    background: #0053C7;
    border-color: #0053C7;
    color: #fff;
}

.back-question{
    background: #e6eefa;
    border-color: #e6eefa;
    color:#0053C7;
}

.back-question:active,
.back-question:focus,
.back-question:hover{
    background: #e6eefa !important;
    border-color: #e6eefa !important;
    color:#0053C7 !important;
}

.back-question:disabled,
.next-question:disabled {
    border-color: #C5C5CE !important;
    background-color: #C5C5CE !important;
    color: #FFFFFF !important;
    opacity: 1;
    max-width: 150px;
}

.all-set-button{
    background: #0053C7;
    color: #fff;
    width: 100px;
    height: 50px;
    padding: 13px 0px 13px 0px;
    border-radius: 30px;
    font-size: 16px;
    margin: auto;
}

.App-header{
    /* position: sticky;   
    top: 0rem;
    padding: 0rem 1rem;
    padding-top: 2.5rem;*/

    position: fixed;
    top: 0rem;
    left: 0rem;
    padding: 2rem 1.8rem;
    padding-bottom: 0rem;

    background: white;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    z-index: 1;
}

.App-header.score{
    position: inherit;
    padding: 2rem 0.8rem 0 !important;
}

.score-snapshot .App-header{
    padding: 2rem 0rem;
}

.score-snapshot .scorecard-section{
    padding: 0rem 0.8rem !important;
}
.App-header>div{
    width: 100%;
}

.App-header .progress{
    width: 100%;
    height: 5px;
}

.App-footer {
    position: fixed;
    /* background: white; */
    width: 100%;    
    bottom: 50px;
    right: 0;    
    padding: 10px 0px;
}

.App-footer .button-section{
    display: flex;
    justify-content: space-evenly;
    padding: 0 1rem;
}
.questionnaire-block {
    /* height: 100vh; */
    /* display: grid; */
    /* max-height:110vh;
    overflow: scroll; */
    
    padding: 0.5rem 1rem;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 80px;
}

.question-block {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.prev-question{
    border:none;
    color: black;
    display: flex;
    align-items: center;
    padding-left: 0px;
}

.prev-question:active,
.prev-question:focus-visible,
.prev-question:hover {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow:none !important;
}

.terminate-button{
    border:none;
    color: black;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 0px;
}

.terminate-button:active,
.terminate-button:hover,
.prev-question:focus-visible,
.prev-question:hover {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow:none !important;
}

.svg-inline--fa {
    height: 20px
}

.question-section{
    margin-bottom:50px;
}

.question-icon {
    /* width: 30px; */
    height: 30px;
    margin-bottom: 10px;
}

.question-type {
    font-family: 'Smitch Sans';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.12em;
    text-align: center;
    text-transform: uppercase;
    color: #78788C;
}

.question-head {
    font-family: "Smitch Sans";
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    margin-top:20px;
    /* max-width: 320px; */
}

.question-desc {
    font-family: 'Smitch Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin: auto;
    margin-top: 10px;
    color: #6C6C7F;
    width:90%;
    max-width: 320px;
}

.questions-options .time-picker-input{
    appearance: none;
    padding: 1rem;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    width: 100%;
    background: white;
    text-align: left !important;
    color: #1c1c1c;
    letter-spacing: 2px;
    display: flex;
    text-transform: capitalize;
}

.questions-options{
    text-align: left;
    margin-top:2rem;
    font-weight: 500;
}

.questions-options .form-check-input {
    opacity: 0;
    position: absolute;
    height:20px;
    width: 20px;
    border-radius: 50%;
}


.questions-options .form-check-input[type="checkbox"]  {
    opacity: 1;
    float: right;
    opacity: 1;
    position: relative;
    float: right;
    top: 40%;
    margin-right: 1rem;
    transform: translateY(-50%);
    border: 2px solid #A1A1AF;
}

.questions-options .form-check {
    min-height: 54px;
    /* max-height: 84px; */
    border-radius: 12px;
    box-shadow: 0px 2px 6px 0.5px #00000040;
    margin-bottom: 20px;
    padding-left: 0em;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 2px solid #ffffff00
}

.questions-options .form-check.single-line {
    height: 54px;
}

.questions-options .form-check.double-line .form-check-input[type="checkbox"]{
    transform: translateY(40%);
}

.questions-options .form-check.selected {
    border: 2px solid #0053C7
}

.questions-options .form-check-input[type="checkbox"]:checked{
    background-color: #30B651;
    border-color: #30B651;
}

.questions-options label {
    font-family: "Smitch Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
.questions-options label span.main {
    padding:5px 0px;
}

.questions-options label span.sub {
    font-family: "Smitch Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color:#606071;
}

.question-options-icon{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.option-label-icon{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.question-section .MuiStack-root{
    margin-top: 50px;
}

.fade-out {
    opacity: 0;
    pointer-events: none;
    
    /* position: relative;
    padding: 2rem;
    top: 0;
    left:0;
    width: 100%; */
}

.fade-in {
    opacity: 1; 

    /* position: absolute;
    padding: 2rem;
    top: 0;
    left:0;
    width: 100%; */
}

/* Animation Div */
.cssanimation{
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.fadeInBottom { animation-name: fadeInBottom }

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to { opacity: 1 }
}
  

/*  */
.preloader-section{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;    
    z-index: 99999;
}

.preloader-section > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.preloader-section img{
  max-width: 500px;
  width: 267.5px;
  height: 267.5px;
  animation-play-state: paused;
}

.loading-text {
    font-family: "Smitch Sans";
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color:#545463;
    margin-top:1rem;
    min-height: 25px;
}

.loading-text span {
    font-weight: 500;
    color:#32323B;
    /* color:#0053C7; */
}
.preloader-section .image-section{
    display: flex;
    justify-content: center;
    align-items: center;
}

.preloader-text{
    font-family: "Smitch Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center; 
    color:#E58600;
    position: absolute;
    left: 0;
    right: 0;
    width: 100px;
    margin: auto;
    background: #f8e9c0;
}

.time-picker-input::-webkit-calendar-picker-indicator {
    width: 100%;
    position: fixed;
    opacity: 0;
}

/* IOS Alert */
.alert-overlay{
    background-color: #00000080;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
}

.loader-overlay{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
}

.ios-alert .alert {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-left: -135px;
	margin-top: -50px;
	width: 270px;
	text-align: center;
	font-family: -apple-system, SF UI Text, Helvetica Neue, Helvetica, Arial, sans-serif !important;
	font-size: 14px;
	line-height: 1.4;
	border-radius: 13px;
	overflow: hidden;
	z-index: 99;
	background-color: #d7d4d4;
    padding: 0;
}


.android-alert .alert {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-left: -135px;
	margin-top: -50px;
	width: 270px;
	text-align: center;
	font-family: 'Roboto', sans-serif !important;
	font-size: 14px;
	line-height: 1.4;
	border-radius: 13px;
	overflow: hidden;
	z-index: 99;
	background-color: #ECE6F0;
    padding: 0;
}


.ios-alert .alert .inner {
	padding: 15px;
}

.android-alert .alert .inner {
	padding: 20px;
}

.ios-alert .alert .title {
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4000000059604645px;
    text-align: center;
}

.android-alert .alert .title {
    font-family: 'Roboto' !important;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0.8rem;
}

.ios-alert .alert .text {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.4000000059604645px;
    text-align: center;
}

.android-alert .alert .text {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
}

.ios-alert .alert .button {
	position: relative;
	height: 44px;
	line-height: 44px;
	font-size: 17px;
	color: #007aff;
	border-radius: 0 0 13px 13px;
	overflow: hidden;
	cursor: pointer;

    display: flex;
}

.android-alert .alert .button {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    padding-top: 0.5rem;
}

.ios-alert .alert .button>div{
    width: 50%;
}

.android-alert .alert .button>div {
    font-family: 'Roboto' !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;

    padding: 0 1rem;
    color: #6750A4;
}

.ios-alert .alert .button>div:first-child{
    border-right: 1px solid #c4c4c4;
}

.ios-alert .alert .button:after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 1px;
	width: 100%;
	display: block;
	background-color: #c4c4c4;
	z-index: 9;
}

/* Quit Loader */
.loading-bar-spinner.spinner {
    left: 50%;
    margin-left: -20px;
    top: 48%;
    margin-top: -20px;
    position: fixed;
    z-index: 19 !important;
    -webkit-animation: loading-bar-spinner 400ms linear infinite;
    animation: loading-bar-spinner 400ms linear infinite;
}

.loading-bar-spinner.spinner .spinner-icon {
    width: 40px;
    height: 40px;
    border: solid 4px transparent;
    border-top-color: #00ffe2 !important;
    border-left-color: #00ffe2 !important;
    border-radius: 50%;
}

@-webkit-keyframes loading-bar-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading-bar-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* ios spinner*/
  .spinner {
    font-size: 30px;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
  }

  .loader.center svg,
  .spinner.center {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loader.center svg{
    width: 90% !important;
    max-width: 280px;
  }
  
  .spinner-blade {
    position: absolute;
    left: 0.4629em;
    bottom: 0;
    width: 5px;
    height: 12px;
    border-radius: 0.5em;
    background-color: transparent;
    transform-origin: center -0.2222em;
    -webkit-animation: spinner-fade 1s infinite linear;
            animation: spinner-fade 1s infinite linear;
  }

  .spinner-blade:nth-child(1) {
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    transform: rotate(0deg);
  }
  .spinner-blade:nth-child(2) {
    -webkit-animation-delay: 0.125s;
            animation-delay: 0.125s;
    transform: rotate(45deg);
  }
  .spinner-blade:nth-child(3) {
    -webkit-animation-delay: 0.250s;
            animation-delay: 0.250s;
    transform: rotate(90deg);
  }
  .spinner-blade:nth-child(4) {
    -webkit-animation-delay: 0.375s;
            animation-delay: 0.375s;
    transform: rotate(135deg);
  }
  .spinner-blade:nth-child(5) {
    -webkit-animation-delay: 0.500s;
            animation-delay: 0.500s;
    transform: rotate(180deg);
  }
  .spinner-blade:nth-child(6) {
    -webkit-animation-delay: 0.625s;
            animation-delay: 0.625s;
    transform: rotate(225deg);
  }
  .spinner-blade:nth-child(7) {
    -webkit-animation-delay: 0.750s;
            animation-delay: 0.750s;
    transform: rotate(270deg);
  }
  .spinner-blade:nth-child(8) {
    -webkit-animation-delay: 0.825s;
            animation-delay: 0.825s;
    transform: rotate(315deg);
   }
  @-webkit-keyframes spinner-fade {
    0% {
      background-color: #0053C7;
    }
    100% {
      background-color: transparent;
    }
  }
  
  @keyframes spinner-fade {
    0% {
      background-color: #0053C7;
    }
    100% {
      background-color: transparent;
    }
  }