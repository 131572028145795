.snapshot-score-card{
    width: 100%;
    /* height:90px; */
    min-height: 90px;
    padding: 10px;
    box-shadow: 0px 2px 6px 0.5px #00000040;
    border-radius: 12px;
    max-width: 500px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column; /* Edit */
}

.snapshot-score-card .heading {
    font-family: "Smitch Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #1C1C1C;
    margin-bottom: 4px;
}

.snapshot-score-card .desc {
    font-family: "Smitch Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 15.26px;
    letter-spacing: 0em;
    text-align: left;
    color: #606071;
    margin-bottom:0px;
}

.snapshot-score-card .badge {
    padding: 7px 12px 7px 12px;
    border-radius: 23px;
    height:30px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.snapshot-score-card .content-section{
    margin-left:10px;
    /* width: 170px; */
    max-width: 170px;
}

.snapshot-score-card .badge.status-1,
.snapshot-score-card .badge.starting {
    background-color: #6D6BDB !important;
}

.snapshot-score-card .badge.status-2,
.snapshot-score-card .badge.progressing {
    background-color: #F967A3 !important;
}

.snapshot-score-card .badge.status-3,
.snapshot-score-card .badge.advancing {
    background-color: #FFDB4D !important;
}

.snapshot-score-card .badge.status-4,
.snapshot-score-card .badge.thriving {
    background-color: #52C1D5 !important;
}

.snapshot-score-card .badge.status-5,
.snapshot-score-card .badge.peak {
    background-color: #2AA248 !important;
}

.snapshot-title {
    font-family: "Smitch Sans";
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
}
.snapshot-desc {
    font-family: "Smitch Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #78788C;
    max-width: 320px;
    margin: auto ;
    margin-bottom:30px;
}

.progress-bar-section{
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*  */

.multicolor-bar .staticValues .value,
.multicolor-bar .scoreValues .value {
    float: left;
    text-align: end;
}

.multicolor-bar .staticValues,
.multicolor-bar .scoreValues {
    max-width: calc(100% + 15px);
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.multicolor-bar .scale .graduation {
    float: left;
    text-align: center;
}

.multicolor-bar .scoreBars .bar {
    float: left;
    height: 10px;
}

.multicolor-bar .scoreBars div.bar:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.multicolor-bar .scoreBars div.bar:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.multicolor-bar .ScoreBarLegends {
    text-align: center;
}


.multicolor-bar .ScoreBarLegends .legend {
    display: inline-block;
    text-align: center;
    font-family: "Smitch Sans";
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: center;
    color: #494956;
    width: 20%;
}


.multicolor-bar .legends .legend .label {
    margin-left: 2px;
    vertical-align: middle;
}
.progress-icon{
    width: 30px;
    height: 33px;
    margin-right: -15px;
}
.progress-icon-text{
    font-family: "Smitch Sans";
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: right;
    color: #1C1C1C;
    margin-right: -25px;
    margin-bottom: 3px;

}

.visibility-hidden{
    visibility: hidden;    
}

.scorecard-section{
    margin-top: 10px;
    margin-bottom: 4rem;
}

/* Progress bar */
.circular-progress-bar {
    width: 58px;
    height: 58px;
    position: relative;
    display: inline-block;
}

.circle {
    width: 100%;
    height: 100%;
}

.circle-background {
    fill: none;
    stroke-width: 8;
}

.circle-progress {
    fill: none;
    stroke-width: 8;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.3s;
}


.circular-progress-bar svg {
    width: 58px;
    height: 58px; 
}

.circular-progress-bar svg {
    display: block;
    /* max-width: 100%; */
    height: auto; 
}

.circular-progress-bar .circle-progress{
    transform: rotate(-90deg);
    transform-origin: center;
}
.circular-progress-bar.activity .circle-background {
    stroke: #ffefea;
}

.circular-progress-bar.activity .circle-progress {
    stroke: #FF6229;
}

.circular-progress-bar.nutrition .circle-background {
    stroke: #e6f3ff;
}

.circular-progress-bar.nutrition .circle-progress {
    stroke: #0A84FF;

}

.circular-progress-bar.sleep .circle-background {
    stroke: #eefafe;
}

.circular-progress-bar.sleep .circle-progress {
    stroke: #5AC9F9;
}

.circular-progress-bar.mental_health .circle-background,
.circular-progress-bar.mental_wellbeing .circle-background {
    stroke: #f5ece4;
}

.circular-progress-bar.mental_health .circle-progress,
.circular-progress-bar.mental_wellbeing .circle-progress {
    stroke: #F2994A;

}

/*  */

.score-snapshot.container{
    padding-left: 1rem;
    padding-right: 1rem;
}

.bottom-section{
    margin-top:5px;
}

.know-more-link{
    font-family: "Smitch Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: none;
    color: #0053C7;    
    cursor: pointer;
}

.know-more-desc {
    font-family: "Smitch Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin-top:30px;
    color: #3D3D48;
    max-width: 320px;
}

.re-assessment-button,
.unlock-plan-button {
    width: 221px;
    height: 50px;
    top: 861px;
    left: 70px;
    border-radius: 28px;
    background-color: #0053C7;
    color: #FFFFFF;
    font-family: "Smitch Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 1rem;
}

.re-assessment-button{    
    background-color: #EEF5FF;
    border-color: #EEF5FF;
    color: #0053C7;
    /* margin-bottom: 4rem; */
}

.unlock-plan-button .premium-icon {
    width: 24px;
    height: 24px;
    margin-left: 5px;
 display:none; 
}

.know-more-section .know-more-img {
    max-width: 280px;
    width: 100%;
    margin-top: 1rem;
}

.activity-icon{
    height:14px;
    width: 14px;
    border:1px solid #545463;
    border-radius: 50%;
    margin-left:2px;
    color:#545463;
    display: none;
}

/* Expand Section */

.expand-section{
    justify-content: space-between;
    align-items: center;
    padding:1rem 0.5rem;
    border-top: 1px solid #E7E7ED;
}

.expand-section .image{
    width: 30px;
    height: 30px;
}

.expand-section .content-section .head-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.expand-section .content-section .sub-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
}

.expand-section .status .status-text{
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
}


.activity.status-text {
    color: #FF6229;
}

.nutrition.status-text {
    color: #0A84FF;
}

.sleep.status-text {
    color: #5AC9F9;
}

.mental_wellbeing.status-text {
    color: #F2994A;
}

/* Slide */

@keyframes slide-down {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}

.slide-container {
    overflow: hidden;
}

.slide-content {
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
}

.slide-container.active .slide-content {
    animation-name: slide-down;
}

.slide-container.inactive .slide-content {
    animation-name: slide-up;
    display: none;
}